























































































import { mountComponent } from 'library/helpers/dom'
import VideoModal from 'library/components/globals/modals/video'
import { isDark } from '@bkwld/light-or-dark'

export default

	props:
		block: Object
		desc:
			type: String
			default: ''
		trace:
			type: Boolean
			default: false

	computed:
		hasButtons: -> !!@block?.buttons?.length

		backgroundColor: -> @block?.backgroundColor

		hasForegroundImage: -> @block?.foregroundImage?.length

		# Is it a dark background color
		isDark: -> isDark @block.backgroundColor if @block?.backgroundColor

		product: -> if @block?.optionalProduct? then return @block.optionalProduct
		collection: -> if @block?.optionalCollection? then return @block.optionalCollection

		shopAllLabel: -> 'Shop & Save 10% with Subscription'

		productButton: -> {
			label: 'Quick Shop'
			product: @product
		}

		# Root classes
		classes: -> [
			@block.layout
			if @isDark then 'fg-light' else 'fg-dark'
		]

		imageContainerClasses: -> [
			'plays-video' if @hasVideo
			'trace' if @trace
		]

		hasVideo: -> @block?.videoUrl?.length

	methods:
		playVideo: ->
			return unless @hasVideo
			await mountComponent VideoModal,
				parent: this
				propsData:
					videoUrl: @block.videoUrl
					label: @block.videoLabel
					focusOnClose: @$refs.playVideoButton

