import { render, staticRenderFns } from "./subscribe-checkbox.vue?vue&type=template&id=35ff092a&scoped=true&lang=pug&"
import script from "./subscribe-checkbox.vue?vue&type=script&lang=coffee&"
export * from "./subscribe-checkbox.vue?vue&type=script&lang=coffee&"
import style0 from "./subscribe-checkbox.vue?vue&type=style&index=0&id=35ff092a&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "35ff092a",
  null
  
)

export default component.exports