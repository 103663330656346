
















import CartCosts from './cart-costs'

export default

	components: {
		CartCosts
	}

	computed:

		checkoutUrl: -> @$store.getters['cart/checkoutUrl']
		cartCount: ->
			count = @$store.getters['cart/itemCount']
			"#{count} #{if count == 1 then 'Item' else 'Items'}"


	methods:

		# Fire checkout click GTM event
		onCheckout: ->
			payload = await @$gtmEcomm.getSimplifiedCheckout @$store.state.cart.id
			@$gtm?.push { event: 'Checkout Clicked', ...payload }

