











import trapFocusMixin from 'library/mixins/trap-focus-mixin'
import Heading from './shared/heading'
import TopLevel from './shared/top-level'
import { wait } from '@bkwld/cloak/services/helpers'
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock'

export default

	mixins: [trapFocusMixin]

	components: { Heading, TopLevel }

	props:
		item: Object

	# on mounted, clear all other scrolls
	# and set the focus on this pane
	mounted: ->
		clearAllBodyScrollLocks()
		wait 100,
			@scrollAndTrapTertiaryNav()

	methods:

		# tell previous pane it's been closed
		closePane: -> @$emit('closePane')

		# handle focus and scroll
		scrollAndTrapTertiaryNav: ->
			disableBodyScroll @$refs.tertiaryNavScroller
			@setTrap(@$refs.tertiaryNavScroller, '2')


