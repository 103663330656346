














import TertiaryNav from './tertiary-nav'
import TopLevel from './shared/top-level'

export default

	components: { TertiaryNav, TopLevel }

	props:
		item: Object

	data: ->
		showTertiaryNav: false

	computed:
		hasChildren: -> @item.links?.length > 0
		children: -> @item.links

	methods:
		onClick: -> @showTertiaryNav = true
		onDestroy: -> @$emit('destroyed')
		closePane: -> @showTertiaryNav = false

