###
Make recommendations when the cart has line items.
###
import { execute } from 'library/services/shopify/storefront'
import { getEntries } from '@bkwld/cloak/services/craft'
import productCard from 'library/queries/shopify/fragments/product-card.gql'
import getCraftDisplay from 'library/queries/craft/get-products-display.gql'
export updateCartRecommendations = (store) ->

	# Watch for line items to get added to the cart
	unwatch = store.watch ((state) -> state.cart.lines), (lines) ->
		return unless lines.length

		# Pick a random product from line
		randomIndex = Math.floor Math.random() * lines.length
		cartProduct = lines[randomIndex].variant.product

		# Lookup recommendations for that product\
		{ recommendations } = await execute
			variables: id: cartProduct.id
			query: '''
				query ($id: ID!) {
					recommendations: productRecommendations(
						productId: $id) {
						...productCard
					}
				}
			''' + productCard

		productHandles = recommendations.map (product) -> product.handle
		craftRecommendations = await getEntries
			query: getCraftDisplay
			variables: handles: productHandles

		craftRecommendationsMap = {}

		craftRecommendations.forEach (product) ->
			craftRecommendationsMap[product.slug] = product.hidden

		recommendations = recommendations.filter (product) ->
			craftRecommendationsMap[product.handle] != true

		# Write the recommendations back to vuex
		store.commit 'cart/setRecommendations', recommendations

		# Stop watching
		unwatch()
