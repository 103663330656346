import * as cartApi from 'library/services/shopify/cart'
import { makeShopifyUrl } from 'library/helpers/routing'
import { cookie } from 'library/helpers/storage'
import { CART_ID_KEY, CART_COUNT_KEY } from 'library/helpers/constants'
import { twoDecimals } from 'library/helpers/formatting'
import { makeImgixUrl } from '@bkwld/cloak/services/helpers'
import {
	makeBundlePrice,
	makeBundleCompareAtPrice,
} from 'library/helpers/products'
BUNDLE_ATTRIBUTE_KEY = 'Bundle'
EMPLOYEE_SHIPPING_COST = 1000

# Make the client client side
export getCart = ->

	# Lookup or make the checkout
	cart = if cartId = cookie.get CART_ID_KEY
	then await cartApi.fetch cartId
	cart = await cartApi.create() unless cart

	# Update the cookie exiration
	# https://regex101.com/r/fVhyob/1/
	cookie.set CART_ID_KEY, cart.id, expires: 14 # Days

	# Return the cart
	return cart

# Stores the Shopify cart state
# https://shopify.dev/docs/ajax-api/reference/cart#get-cart-js
export state = ->

	# Default values for Shopify properties we consume
	id: null
	lines: []
	estimatedCost:
		subtotalAmount: amount: 0
		totalAmount: amount: 0
		totalTaxAmount: amount: 0
	discountCodes: []
	buyerIdentity: customer: id: null
	checkoutUrl: '' # The checkout URL

	# App-specific cart values
	open: false # The open/close state of the flyout
	hydrated: false # Whether we've fetched the cart yet
	recommendations: [] # Fetched via cart-recommendations service

export getters =

	# Helper for accessing totals
	subtotal: (state) -> state.estimatedCost.subtotalAmount.amount
	tax: (state) -> state.estimatedCost.totalTaxAmount?.amount || 0

	# Deduct the inflated employee shipping rate that is used to prevent
	# employees who are subscribing from being offered free shipping.
	# https://app.asana.com/0/1200777795870740/1202059613688165/f
	total: (state, getters) ->
		total = state.estimatedCost.totalAmount.amount
		if total - getters.subtotal == EMPLOYEE_SHIPPING_COST
		then total - EMPLOYEE_SHIPPING_COST
		else total

	# Get the quantity of items in the cart
	itemCount: (state, getters) ->
		unless state.hydrated then return cookie.get(CART_COUNT_KEY) || 0
		getters.lines.reduce (sum, line) ->
			sum + line.quantity
		, 0

	# get the recommended products length
	recommendations: (state) -> state.recommendations.length || 0

	# Group bundles in the lines
	lines: (state, getters, rootState) ->
		state.lines.reduce (lines, line) ->

			# If the line isn't for a bundle, add the line normally
			unless bundleAttribute = line.attributes.find ({ key }) ->
				key == BUNDLE_ATTRIBUTE_KEY
			then return [...lines, line]

			# Return early if this bundle has already been added to the lines
			bundleSlug = bundleAttribute.value
			return lines if lines.find (line) -> line.bundleSlug == bundleSlug

			# Lookup bundle in globals
			return lines unless bundle = rootState.globals.bundles.find (bundle) ->
				bundle.slug == bundleSlug

			# Collect all the variants of this bundle from the lines
			bundleLines = state.lines.filter (line) ->
				line.attributes.find ({ key, value }) ->
					key == BUNDLE_ATTRIBUTE_KEY && value == bundleSlug
			bundleVariants = bundleLines.map (line) -> line.variant

			# Calculate the total prices
			subtotalAmount = bundleLines.reduce (sum, line) ->
				sum + Number line.estimatedCost.subtotalAmount.amount
			, 0
			totalAmount = bundleLines.reduce (sum, line) ->
				sum + Number line.estimatedCost.totalAmount.amount
			, 0

			# Make up a new line for the bundle
			lines.push
				id: bundleSlug
				bundleSlug: bundleSlug
				bundleLines: bundleLines
				quantity: line.quantity
				estimatedCost:
					subtotalAmount: amount: twoDecimals subtotalAmount
					totalAmount: amount: twoDecimals totalAmount
				variant:
					price: amount: makeBundlePrice bundleVariants
					compareAtPrice: amount: makeBundleCompareAtPrice bundleVariants
					title: 'Bundle'
					product:
						title: bundle.title
						handle: bundle.slug
					image: bundle.images
					sellingPlanAllocations: []
			return lines
		, []

	# Determine if the cart contains a subscription
	hasSubscription: (state) ->
		!!state.lines.find (line) -> !!line.sellingPlanAllocation

	# Determine if the cart contains one time purchaess
	hasNonSubscription: (state) ->
		!!state.lines.find (line) -> !line.sellingPlanAllocation

	# Use Netlify function that uses Shopify Multipass to bind the customer
	# account to the checkout URL
	checkoutUrl: (state, getters, rootState, rootGetters) ->
		if rootGetters['customer/isAuthenticated']
			host = process.env.NUXT_APP_URL || ''
			path = '/.netlify/functions/auth-redirect'
			return "#{host}#{path}?" + new URLSearchParams
				url: state.checkoutUrl
				cartId: state.id
		else state.checkoutUrl

export mutations =

	# Replace the current cart with the new one
	replace: (state, cart) -> Object.assign state, cart

	# Open/close the cart flyout
	open: (state) -> state.open = true
	close: (state) -> state.open = false

	# Mark has hydrated
	isHydrated: (state) -> state.hydrated = true

	# Store cart recommendations
	setRecommendations: (state, products) -> state.recommendations = products

export actions =

	# Add an item to the cart
	addItem: (
		{ state, dispatch, commit },
		{ id: variantId, quantity = 1, sellingPlanId, attributes }
	) ->
		try
			await dispatch 'fetchUnlessHydrated'
			cart = await cartApi.addVariant {
				cartId: state.id
				variantId
				quantity: parseInt quantity
				sellingPlanId
				attributes
			}
			commit 'replace', cart
		catch e then console.error e

	# Add an bundle to cart (by adding all of it's variants)
	addBundle: (
		{ state, dispatch, commit },
		{ variantIds, quantity = 1, bundleSlug }
	) ->
		try
			await dispatch 'fetchUnlessHydrated'
			cart = await cartApi.addVariants
				cartId: state.id
				variantIds: variantIds
				quantity: parseInt quantity
				attributes: [BUNDLE_ATTRIBUTE_KEY]: bundleSlug
			commit 'replace', cart
		catch e then console.error e

	# Change an item's quantity. If the quantity is 0, delete the line. Auto
	# close the cart if the total items equals 0.
	updateLine: (
		{ state, dispatch, commit, getters },
		{ quantity, id: lineId, sellingPlanId }
	) ->
		try
			await dispatch 'fetchUnlessHydrated'

			# Lookup the referenced line and, if it's a bundle, use the lineIds
			# of all the real, hidden, lines that comprise the bundle
			line = getters.lines.find (line) -> line.id == lineId
			lineIds = unless line.bundleLines then [ lineId ]
			else line.bundleLines.map ({ id }) -> id

			# Do nothing if the quantity wouldn't change
			return unless do ->
				for lineId in lineIds
					line = state.lines.find (line) -> line.id == lineId
					return true if line.quantity != quantity || line.sellingPlanAllocation?.sellingPlan?.id != sellingPlanId

			# If there are line attributes, don't clear them. This assumes that the
			# attributes are the same on all lines that are being updated, which is
			# the case for bundles.  Which is the only current use case for line
			# attributes. Converting the attributes into a hash so the API is similar
			# to the addVariant methods.
			firstLine = state.lines.find (line) -> line.id == lineId
			attributes = firstLine.attributes.reduce (attributes, { key, value }) ->
				attributes[key] = value
				return attributes
			, {}

			# Change the quantity
			if quantity > 0
			then cart = await cartApi.updateLines {
				cartId: state.id
				quantity: parseInt quantity
				lineIds
				sellingPlanId
				attributes
			}

			# Or remove quantity if new value would be 0
			else cart = await cartApi.deleteLines {
				cartId: state.id
				lineIds
			}

			# Update the cart and close it if empty
			commit 'replace', cart
			commit 'close' if getters.itemCount == 0
		catch e then console.error e

	# Fetch the current cart status or start a new cart
	fetch: ({ commit, state }) ->
		try cart = await getCart()
		catch e
			cookie.remove CART_ID_KEY
			cart = await getCart()
		commit 'replace', cart
		commit 'isHydrated' unless state.hydrated

	# Fetch the cart unless it is already hydrated
	fetchUnlessHydrated: ({ state, dispatch }) ->
		return if state.hydrated
		dispatch 'fetch'

	# Apply a discount code(s) to the cart
	applyDiscount: ({ state, dispatch, commit }, { code }) ->
		try
			await dispatch 'fetchUnlessHydrated'
			cart = await cartApi.updateDiscounts
				cartId: state.id
				codes: [ code ]
			commit 'replace', cart
		catch e then console.error e

	# Remove all discount codes
	removeDiscounts: ({ state, dispatch, commit }) ->
		try
			await dispatch 'fetchUnlessHydrated'
			cart = await cartApi.updateDiscounts
				cartId: state.id
				codes: [ ]
			commit 'replace', cart
		catch e then console.error e

	# Link a customer by access token to the cart
	linkCustomer: ({ state, commit }, { accessToken }) ->
		try
			cart = await cartApi.linkCustomer
				cartId: state.id
				accessToken: accessToken
			commit 'replace', cart
		catch e then console.error e
