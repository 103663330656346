










export default

	computed:
		classes: -> [
			'site-banner' if @$store?.state?.globals?.banner?
		]

