
























export default

	props:
		closable: Boolean

	data: ->
		query: ''
		previouslyFocusedElement: null

	methods:
		search: ->
			return if @query.length <= 0
			@$router.push
				path: '/search'
				query: query: @query
			@$gtm?.push
				event: 'Search'
				searchTerm: @query

			# When we submit a search, emit a click to close the dropdown.
			@emitClick()

		emitClick: -> @$emit 'click'

	mounted: ->

		# On mount (when dropdown is opened), set focus to the
		# search input, and save the previously focused element.
		@previouslyFocusedElement = document.activeElement
		@$el.querySelector('input')?.focus()

	# On destroy (when dropdown is closed), return focus to the
	# previously focused element.
	beforeDestroy: ->
		@previouslyFocusedElement?.focus()

