
















export default
	methods:
		closeCart: ->
			@$emit 'close'

