













































import PurchaseOptions from '~/components/globals/product/purchase-options'

export default
	components: { PurchaseOptions }

	props:
		product: Object

	data: ->
		# Set the initial variant
		currentVariant: @product?.variants?[0]
		loaded: false

	# focus on the modal's contents when mounted
	mounted: ->
		@$refs.modal.$el.querySelector('.bvm-slot').focus()
		@$nextTick =>
			@loaded = true

	methods:
		closed: ->
			@$refs.modal.close()

		updateVariant: (variant) -> @currentVariant = variant

	computed:
		# deeplink url
		productUrl: -> "/products/#{@slug}"

		slug: -> @product.slug || @product.handle


