###
Boot up cart related logic
###
import { addGtmCartSubscribers } from 'library/services/gtm/cart-events'
import { applyAutomaticCartItems } from 'library/services/cart/automatic-cart-items'
import { lazyHydrateCart } from 'library/services/cart/hydrate-cart'
import { updateCartRecommendations } from 'library/services/cart/cart-recommendations'
import { watchForQueryDiscountCodes } from 'library/services/cart/discounts'
export default ({ app, store }, inject) ->
	applyAutomaticCartItems store
	addGtmCartSubscribers store, app.$gtmEcomm
	updateCartRecommendations store
	lazyHydrateCart store
	watchForQueryDiscountCodes store, app.router
