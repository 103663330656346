









import line1 from 'library/assets/images/olio-nuovo/1.png'
import line2 from 'library/assets/images/olio-nuovo/2.png'
import line3 from 'library/assets/images/olio-nuovo/3.png'
import line4 from 'library/assets/images/olio-nuovo/4.png'
import line5 from 'library/assets/images/olio-nuovo/5.png'
import line6 from 'library/assets/images/olio-nuovo/6.png'
import line7 from 'library/assets/images/olio-nuovo/7.png'
import line8 from 'library/assets/images/olio-nuovo/8.png'

export default

	props:
		index:
			type: String
			default: '1'

	data: -> { line1, line2, line3, line4, line5, line6, line7, line8 }

	computed:
		line: -> switch @index
			when '1' then return line1
			when '2' then return line2
			when '3' then return line3
			when '4' then return line4
			when '5' then return line5
			when '6' then return line6
			when '7' then return line7
			when '8' then return line8


