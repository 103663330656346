import { render, staticRenderFns } from "./simple-marquee.vue?vue&type=template&id=7cd1f70e&scoped=true&lang=pug&"
import script from "./simple-marquee.vue?vue&type=script&lang=coffee&"
export * from "./simple-marquee.vue?vue&type=script&lang=coffee&"
import style0 from "./simple-marquee.vue?vue&type=style&index=0&id=7cd1f70e&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7cd1f70e",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ResponsiveCraftVisual: require('/opt/build/repo/node_modules/@bkwld/cloak/components/responsive-craft-visual.vue').default,Breadcrumbs: require('/opt/build/repo/library/components/globals/breadcrumbs.vue').default,Wysiwyg: require('/opt/build/repo/node_modules/@bkwld/cloak/components/wysiwyg.coffee').default,Btn: require('/opt/build/repo/nuxt-app/components/globals/btn/btn.vue').default})
