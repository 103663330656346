










































export default

	props:
		menu: Object

	computed:

		classes: -> [
			'has-primary' if @primaryLinks.length
			'no-primary' if !@primaryLinks.length
		]

		primaryLinks: -> @menu.links.filter (link) ->
			link.__typename == 'menu_primaryLinkMenu_BlockType'

		standardLinks: -> @menu.links.filter (link) ->
			link.__typename == 'menu_link_BlockType'

	methods:
		hasTitleLink: (item) -> item?.headingLink?.length > 0


