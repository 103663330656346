###
Add or remove automatic cart items based on their conditions
###
import Vue from 'vue'
import { execute } from 'library/services/shopify/storefront'
import { getShopifyId } from 'library/helpers/formatting'

# Add all listeners and behaviors
export applyAutomaticCartItems = (store) ->

	############################################################################
	# Actions
	############################################################################

	# Set the quantity of an item in the cart
	addToCart = (productHandle, quantity) ->

		# Get the Shopify variant for the product
		return unless variant = await getPrimaryVariant productHandle
		return unless variant.availableForSale

		# If the product is already in the cart, update it
		if line = findVariantLine variant.id
			if quantity > getCartVariantCount variant.id
			then await store.dispatch 'cart/updateLine', { id: line.id, quantity }

		# Else, it's not in the cart, so add it
		else await store.dispatch 'cart/addItem', { id: variant.id, quantity }


	############################################################################
	# Helpers
	############################################################################

	# Get the variant of a product if it's available to sell
	getPrimaryVariant = (productHandle) ->
		{ product } = await execute
			variables: handle: productHandle
			query: '''
				query getProductVariants($handle:String!) {
					product: productByHandle(handle:$handle) {
						variants(first:1) {
							edges {
								node {
									id
									availableForSale
								}
							}
						}
					}
				}
			'''
		return product?.variants?[0]

	# Get the line of a variant
	findVariantLine = (variantId) ->
		store.state.cart.lines.find (line) ->
			line.variant?.id == variantId

	# Find the count of variants in cart since it may be spread across multiple
	# lines once the discount is applied
	getCartVariantCount = (variantId) ->
		store.state.cart.lines.reduce (count, line) ->
			return count unless line.variant?.id == variantId
			count + line.quantity
		, 0

	# Like getCartVariantCount but using product handles instead
	getCartProductCount = (productHandle) ->
		store.state.cart.lines.reduce (count, line) ->
			return count unless line.variant?.product?.handle == productHandle
			count + line.quantity
		, 0

	# Get the line of a product by slug
	findProductLine = (productHandle) ->
		store.state.cart.lines.find (line) ->
			line.variant?.product?.handle == productHandle

	# Get the active conditions, based on current time
	getCartContainsConditions = ->
		now = new Date
		(store.state.globals.automaticCartItems?.conditions || [])
		.filter ({ type }) -> type.includes 'cartContains'
		.filter (condition) -> switch
			when condition.startTime and now < new Date condition.startTime
				return false
			when condition.endTime and now >= new Date condition.endTime
				return false
			else true

	# Get the number of times the cart matches the criteria. For instance, if
	# there are 4 of the criteria product in the cart, this would return 4.
	getCartCountainsCriteriaCount = (criteria) ->

		# Make an array of the number of times each product is found in the cart.
		# There may be minimum quantity set in the CMS.  For example, if the
		# quantity is 2, then 4 of of that product in the cart equals a count of 2.
		counts = criteria.map ({ product, quantity }) ->
			product = product?[0] # It comes from Craft as an array
			cartQuantity = getCartProductCount product.slug
			Math.floor cartQuantity / quantity

		# Return the lowest count value of all criteria
		Math.min ...counts

	############################################################################
	# Listeners
	############################################################################

	# Watch the amount of times the cart matches cart contains criteria
	addCartContainsWatchers = ->
		getCartContainsConditions().forEach ({ injectedProducts, criteria }) ->
			store.watch(
				(state) -> getCartCountainsCriteriaCount criteria
				(count, oldCount) ->
					if count > oldCount
					then for { slug } in injectedProducts
						addToCart slug, count
				immediate: true
			)

	# Watch for the globals to be hydrated
	store.watch(
		(state) -> state.globals.hydrated
		(isHydated) -> addCartContainsWatchers() if isHydated
		immediate: true
	)
