import { render, staticRenderFns } from "./default.vue?vue&type=template&id=753d2f12&scoped=true&lang=pug&"
import script from "./default.vue?vue&type=script&lang=coffee&"
export * from "./default.vue?vue&type=script&lang=coffee&"
import style0 from "./default.vue?vue&type=style&index=0&id=753d2f12&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "753d2f12",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LayoutHeaderDesktop: require('/opt/build/repo/library/components/layout/header/desktop.vue').default,LayoutHeaderMobile: require('/opt/build/repo/library/components/layout/header/mobile.vue').default,LayoutFooter: require('/opt/build/repo/library/components/layout/footer/footer.vue').default})
