


















import CartProductCard from '~/components/globals/cards/product/cart-product'
import breakpoints from 'library/assets/vars/breakpoints'

export default

	components: {
		CartProductCard
	}

	data: ->
		loaded: false
		config:
			slidesPerPage: 2
			peekRight: 100
			showDots: true
			gutter: 25

			responsive: [
				{
					peekLeft: 'var(--peek)'
					peekRight: 'var(--peek)'
					maxWidth: parseInt breakpoints.mobile
					slidesPerPage: 1
				}
			]


	mounted: ->
		@loaded = true


	computed:

		# Get first 2 recommendations
		recommendations: -> @$store.state.cart.recommendations.slice 0,3

