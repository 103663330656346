































import axios from 'axios'
import { IDENTIFY_EMAIL_EVENT } from 'library/helpers/constants'

export default

	props:
		listID: String
		cta: String

	data: ->
		form:
			email: ''
		loading: false
		successMsg: ''
		errorMsg: ''

	computed:

		klaviyoListId: -> @listID
		klaviyoEndpoint: ->
			"https://manage.kmail-lists.com/ajax/subscriptions/subscribe"

		klaviyoPostData: ->
			g: @klaviyoListId
			email: @form.email

		params: ->
			_params = new URLSearchParams()
			_params.append('g', @klaviyoListId)
			_params.append('email', @form.email)
			return _params


	methods:
		onSubmit: ->
			try
				@successMsg = ""
				@errorMsg = ""
				@loading=true

				response = await axios.post @klaviyoEndpoint, @params


				@loading=false

				# Handle errors in response
				if response.data.errors.length
					@errorMsg = response.data.errors
				else
					@$gtmEcomm.pushEvent IDENTIFY_EMAIL_EVENT, { @email }
					@successMsg = "Successful Signup"

			catch error
				@errorMsg = error
				@success_message = ""
				@loading=false



