













export default

	# An array of Link objects; Links should be:
	# {
	#		text: String (Required)
	#		href: String (optional; leave empty to make a span)
	# }
	props: links: Array

	methods:
		active: (link) ->
			if link.href == @$route.path then return true
			else return false

