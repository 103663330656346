











































import 'vue-height-tween-transition/index.css'
import HeightTween from 'vue-height-tween-transition'

export default
	components: { HeightTween }

	props:
		currentVariant: Object
		pdpLoading: Boolean

	data: ->
		quantity: 1
		purchaseType: 'once'
		sellingPlanId: @currentVariant?.sellingPlanAllocations[0]?.sellingPlan?.id
		loading: false

	mounted: ->
		@$wait 300, =>
			if @$route.query.subscribe == 'true' then @purchaseType = 'subscription'

	computed:

		oneTimePurchase: ->
			if @compareAtPrice
				return {

					label: """
						<span>One-Time Purchase</span>
						<span class="right-label"><span class='regular'>#{@$formatMoney(@compareAtPrice)}</span><span class='discount'>#{@$formatMoney(@price)}</span></span>
					"""
					value: 'once'
				}

			else
				return {

					label: """
						<span>One-Time Purchase</span>
						<span class="right-label">#{@$formatMoney(@price)}</span>
					"""
					value: 'once'
				}

		# Make the purchase type options
		purchaseTypes: -> @$nonEmpty [
			@oneTimePurchase
			{ # The subscribe options with dynamic values
				label: """
					<span>#{@subscribeAndSave} <a href="/subscribe">Details</a></span>
					<span class=\"right-label\">
						#{@$formatMoney(@subscriptionPrice)}
					</span>
				"""
				value: 'subscription'
			} if @hasSubscriptions
		]

		subscribeAndSave: ->
			if @subscriptionDiscount != "0%"
				"Subscribe and Save #{@subscriptionDiscount}"
			else "Subscribe"

		# Helper for getting the current price
		price: -> @currentVariant.price.amount
		compareAtPrice: -> @currentVariant.compareAtPrice?.amount

		# Lookup the selected sellingPlanId
		sellingPlanAllocation: ->
			@currentVariant.sellingPlanAllocations.find (allocation) =>
				allocation.sellingPlan.id == @sellingPlanId

		# Calculate the discount percentage based on the chosen selling plan
		subscriptionDiscount: ->
			return unless @subscriptionPrice
			Math.round(100 * (1 - @subscriptionPrice / @price)) + '%'

		# Get the discounted price based on the chosen selling plan
		subscriptionPrice: ->
			@sellingPlanAllocation?.priceAdjustments[0]?.price.amount

		# Is the variant sold out?
		isSoldOut: -> not @currentVariant.available

		quantityAvailable: ->

			# if the product has the flag 'purchaseWhenNotInStock' then
			# regardless of inventory, it can still be purchased, so set the
			# quantity available to be the max value of the number-stepper, 999
			# https://app.asana.com/0/1200777795870740/1203144504236655/f
			if @currentVariant?.purchaseWhenNotInStock then return 999
			else return @currentVariant?.quantityAvailable

		# Does this variant support subscription
		hasSubscriptions: -> @currentVariant?.sellingPlanAllocations?.length > 0

		# Is the user subscribing?
		isSubscribing: -> @purchaseType == 'subscription'

		# Should adding to cart be disabled?
		disabled: -> @isSoldOut

		# Make the add to cart label
		addToCardLabel: -> switch
			when @isSubscribing then 'Subscribe'
			else 'Add to Cart'

		# Make the add to cart payload as computed property for introspection
		addProductToCartPayload: ->
			id: @currentVariant.id
			quantity: @quantity
			sellingPlanId: @sellingPlanId if @isSubscribing

		# Make the payload for adding a bundle to cart
		addBundleToCartPayload: ->
			variantIds: @currentVariant.bundleVariantIds
			quantity: @quantity
			bundleSlug: @currentVariant.bundleSlug

	methods:

		# Add this variant to cart
		addToCart: ->
			@loading = true
			if @currentVariant.isBundle
			then await @$store.dispatch 'cart/addBundle', @addBundleToCartPayload
			else await @$store.dispatch 'cart/addItem', @addProductToCartPayload
			@$store.commit 'cart/open'
			@$emit 'added-to-cart'
			@loading = false

		mountSubscribeModal: ->
			await mountOnBody SubscribeModal,
				parent: this

	watch:
		purchaseType: -> @$emit('purchaseTypeChange', @purchaseType)


