



















export default
	computed:
		customer: -> @$store.state.customer
		email: -> @customer?.email

