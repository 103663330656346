


























































import HeightTween from 'vue-height-tween-transition'
import debounce from 'lodash/debounce'
import 'vue-height-tween-transition/index.css'
import CartNumberStepper from 'library/components/globals/form/cart-number-stepper'
import SubscribeCheckbox from 'library/components/globals/form/subscribe-checkbox'

export default

	components: { HeightTween, CartNumberStepper, SubscribeCheckbox }

	props:
		item: Object

	data: ->
		quantity: @item.quantity
		loading: false
		isSubscribing: !!@item?.sellingPlanAllocation
		sellingPlanId:
			if @item.sellingPlanAllocation
				@item.sellingPlanAllocation.sellingPlan.id
			else
				@item?.variant?.sellingPlanAllocations?[0]?.sellingPlan?.id

	computed:

		# Does this line have a subscription
		hasSubscription: -> !!@item?.sellingPlanAllocation

		currentVariant: -> @item?.variant

		primarySellingPlans: -> @currentVariant?.sellingPlanAllocations

		lineTotal: -> @item.estimatedCost.totalAmount.amount

		# Use the discount's title as the label when an item is made free.
		# It's expected this will be like "Free Gift".
		discountedFreeLabel: ->
			return unless @isFree
			(@item.discountAllocations.find ({ title }) -> !!title)?.title

		attrs: -> @item.customAttributes

		classes: -> 'is-loading' if @loading

		# Get the product type
		productType: -> @currentVariant.product.productType

		# Make the URL progromatically. Don't make a URL if a free sample, it's
		# assumed these shouldn't have PDPs.
		url: -> '/products/' + @currentVariant.product.handle

		# Make a clean object to prevent errors about mutating object
		image: -> @currentVariant.image

		subscriptionOptions: ->
			@item?.variant?.sellingPlanAllocations.map ({sellingPlan, priceAdjustments}) -> {
				value: sellingPlan.id
				label: sellingPlan.name
			}

		# Build the playload for updating the cart line, for introspection
		updateLinePayload: ->
			id: @item.id
			quantity: @quantity
			sellingPlanId: if @isSubscribing then @sellingPlanId else null

		# Helper for getting the current price
		price: -> @currentVariant.price

		# Lookup the selected sellingPlanId
		sellingPlanAllocation: ->
			@primarySellingPlans.find (allocation) =>
				allocation.sellingPlan.id == @sellingPlanId

		# Calculate the discount percentage based on the chosen selling plan
		subscriptionDiscount: ->
			return unless @subscriptionPrice
			Math.round(100 * (1 - @subscriptionPrice / @price.amount)) + '%'

		# Get the discounted price based on the chosen selling plan
		subscriptionPrice: ->
			@sellingPlanAllocation?.priceAdjustments[0]?.price.amount

		# Is this a free product?
		isFree: -> parseFloat(@lineTotal) == 0

		# Is this line for a bundle
		isBundle: -> !!@item.bundleSlug

		# shorthand for titles
		productTitle: -> @item.variant?.product?.title
		variantTitle: ->
			if @item.variant.title && @item.variant.title != "Default Title"
				return @item.variant.title

		subscribeAndSave: ->
			if @subscriptionDiscount != "0%"
				"Subscribe & Save (#{@subscriptionDiscount})"
			else "Subscribe"

		# Check for compare at price
		hasCompareAtPrice: -> @item.variant.compareAtPrice?.amount > 0

	watch:

		# Trigger updating of the cart
		quantity: -> @updateQuantity()
		sellingPlanId: -> @updateQuantity()
		isSubscribing: -> @updateQuantity()

		# Update the internal quantity if something else has touched it
		'item.quantity': (val) -> @quantity = val

	methods:

		deleteLine: -> @quantity = 0

		# Trigger updating of the cart on a debounce
		updateQuantity: ->
			@loading = true
			await @$store.dispatch 'cart/updateLine', @updateLinePayload
			@loading = false

