import * as storefront from 'library/services/shopify/storefront'
import { makeImgixUrl, nonEmpty } from '@bkwld/cloak/services/helpers'
import { getShopifyId, twoDecimals } from './formatting'
import getShopifyProductQuery from '~/queries/shopify-product.gql'
import memoize from 'lodash/memoize'

# Product tags that indicate a secret product
SECRET_PRODUCT_TAGS = [
	'Private Label Sample'
	'Grower Private Label'
	'Food Service Sample'
]

# Take an array of Craft product entries and merge Shopify data with them. This
# preserves the original order of the products.
export mergeShopifyProductCards = (products) ->

	return [] unless products?.length

	# Get Shopify and bundle data for products. If Shopify data isn't found,
	# an exception is thrown, which we catch and use to remove that product
	# from the listing.
	products = await Promise.all products.map (product) ->
		try await mergeShopifyProductCard product
		catch error then console.warn error

	# Remove all empty products (those that errored while getting data)
	return nonEmpty products

# Merge Shopify data into a single card
export mergeShopifyProductCard = memoize (product) ->

	return unless product

	# Reject if marked a secret product in Craft
	return if product.hidden and not product.showHidden

	# Merge Shopify data into the product object
	shopifyProduct = await getShopifyDataForCraftProduct product
	product = { ...product, ...shopifyProduct }

	# Reject product that have a "secret" tag (they don't get listed)
	unless product.showHidden
		for tag in product.tags || []
			return if tag in SECRET_PRODUCT_TAGS

	# If a bundle, replace variants with simulated variant
	if product.isBundle
	then product.variants = [ makeBundleCurrentVariant product ]

	# Remove keys not needed for cards
	delete product.description
	delete product.showHidden

	# Return the final product
	return product

# Use the id and the collection showHidden modifier of the product as memoize
# resolver.  The latter was needed because a product would have that on some
# collections but not others.
, (product) -> [product?.id, product?.showHidden].join '|'

# Merge Shopify data into a Craft product object
export getShopifyDataForCraftProduct = (product) ->
	switch product.__typename
		when 'products_product_Entry' then {
			isBundle: false
			...(await getShopifyProductByHandle product.slug)
		}
		when 'products_bundle_Entry'
			variants = await getShopifyBundleVariants product
			{
				isBundle: true
				variants # Add variants array
				...makeAdditionalProductDataForBundle product
			}

# Get the Shopify product data given a Shopify product handle
getShopifyProductByHandle = (productHandle) ->

	# Query Storefront API
	{ product } = await storefront.execute
		query: getShopifyProductQuery
		variables: handle: productHandle
	unless product then throw "No Shopify product found for #{productHandle}"

	# Add URLs to each variant for easier iteration later
	product.variants = product.variants.map (variant) =>
		variantIdNum = getShopifyId variant.id
		url = "#{process.env.URL}/products/#{product.handle}/#{variantIdNum}"
		return { ...variant, url }

	# Return the product
	return product

# Make properties on the product data for Shopify-like fields
makeAdditionalProductDataForBundle = (craftProduct) ->

	# Make the canonnical url for a bundle
	url: "#{process.env.URL}/products/#{craftProduct.slug}"

	# The bundle vendor, calculated from Craft categories
	vendor: craftProduct.categories.find (category) ->
		category.parent.slug == 'brand'
	?.title

	# The bundle product type, calculated from Craft categories
	productType: craftProduct.categories.find (category) ->
		category.parent.slug == 'product-type'
	?.title

# For each referenced variant entry on the Craft bundle, lookup the variant
# info in Shopify
getShopifyBundleVariants = (product) ->
	variants = await Promise.all product.variants.map (craftVariant) ->

		# Lookup the Shopify product given the handle reference from Craft
		shopifyProduct = await getShopifyProductByHandle craftVariant.productSlug

		# Find the Shopify variant object whose sku matches the Craft variant
		# slug.
		unless variant = shopifyProduct.variants.find ({ sku }) ->
			sku?.toLowerCase() == craftVariant.sku?.toLowerCase()
		then throw "Bundle variant not found for #{craftVariant.sku}"

		# Return the variant with a subset of product data (just what's needed
		# elsewhere)
		{ ...variant, product:
			id: shopifyProduct.id
		}

	# Return the variants array
	return variants

# Make a fake currentVariant that has many of the properties of a Shopify
# variant
export makeBundleCurrentVariant = (product) ->
	isBundle: true

	# Basic data that we sync with product level data
	id: product.id
	title: product.title
	image: src: makeImgixUrl product.images?[0]?.path
	url: product.url

	# Use the slug as the sku
	sku: product.slug

	# Disable subscriptions for bundles
	sellingPlanAllocations: []

	# Calculate prices
	price: amount: makeBundlePrice product.variants
	compareAtPrice: amount: makeBundleCompareAtPrice product.variants

	# The bundle is available as long as all child products are available
	available: do ->
		return false if product.variants.find ({ available }) -> !available
		return true

	# The ids of all the variants in the bundle
	bundleVariantIds: product.variants.map ({ id }) -> id

	# The slug of the bundle product
	bundleSlug: product.slug

# Calculate the bundle price by summing all the variant prices
export makeBundlePrice = (variants) ->
	twoDecimals variants.reduce (total, variant) ->
		total + Number variant.price.amount
	, 0

# Calculate the compare at price by summing all variant prices. This is null
# if the compare at pricing is no different than the budnle price.
export makeBundleCompareAtPrice = (variants) ->
	price = makeBundlePrice variants
	compareAtPrice = twoDecimals variants.reduce (total, variant) ->
		total + Number variant.price.compareAtPrice || variant.price.amount
	, 0
	return compareAtPrice unless compareAtPrice == price
