




















export default

	props:
		origin: String

	# focus on the modal's contents when mounted
	mounted: ->
		@$refs.modal.$el.querySelector('.bvm-slot').focus()

	computed:
		comparisonImageGlobal: -> @$store.state.globals.productFields?.comparisonImageGlobal
		comparisonImageCA: -> @$store.state.globals.productFields?.comparisonImageCA

	methods:
		closed: ->
			document.querySelector('body').style = ''
			@$nextTick => @$el.remove()


