import { render, staticRenderFns } from "./price.vue?vue&type=template&id=ea0627f8&scoped=true&lang=pug&"
import script from "./price.vue?vue&type=script&lang=coffee&"
export * from "./price.vue?vue&type=script&lang=coffee&"
import style0 from "./price.vue?vue&type=style&index=0&id=ea0627f8&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ea0627f8",
  null
  
)

export default component.exports