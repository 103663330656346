import { render, staticRenderFns } from "./line-item.vue?vue&type=template&id=a060ba44&scoped=true&lang=pug&"
import script from "./line-item.vue?vue&type=script&lang=coffee&"
export * from "./line-item.vue?vue&type=script&lang=coffee&"
import style0 from "./line-item.vue?vue&type=style&index=0&id=a060ba44&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a060ba44",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ProductVisual: require('/opt/build/repo/library/components/globals/product-visual.coffee').default,SmartLink: require('/opt/build/repo/nuxt-app/components/globals/smart-link.coffee').default,FormSubscriptionIntervalSelector: require('/opt/build/repo/library/components/globals/form/subscription-interval-selector.vue').default})
