###
Sync the current cart count with a cookie and only hydrate the cart when it's
viewed or updated
###
import { cookie } from 'library/helpers/storage'
import { CART_COUNT_KEY } from 'library/helpers/constants'
export lazyHydrateCart = (store) ->

	# Keep the cart count cookie up to date
	store.watch ((state, getters) -> getters['cart/itemCount'])
	, ((count) -> cookie.set CART_COUNT_KEY, count)

	# Call fetch when the cart/open is mutated
	store.subscribe (mutation, state) ->
		return unless mutation.type == 'cart/open'
		await store.dispatch 'cart/fetchUnlessHydrated'

	# Clear the cart count after checkout
	if location.pathname.match /\/checkouts\/[^\/]+\/thank_you$/
	then cookie.set CART_COUNT_KEY, 0

	# Watch for an access token on the customer and link to the cart once _it's_
	# hydrated
	store.watch(
		(state) -> state.cart.hydrated
		(hydrated) ->

			# Wait cart is hydrated
			return unless hydrated

			# Watch for access token
			store.watch(
				(state) -> state.customer.accessToken
				(accessToken) ->
					return unless accessToken
					store.dispatch 'cart/linkCustomer', { accessToken }
				immediate: true
			)
		immediate: true
	)
