


















import { clearAllBodyScrollLocks } from 'body-scroll-lock'

export default

	props:
		videoUrl: String
		label: String
		focusOnClose: Object

	data: -> player: null

	mounted: ->
		@$nextTick ->
			@initScript()

	computed:
		videoId: ->
			# expecting a url like https://vimeo.com/483672878
			return @videoUrl.replace('https://vimeo.com/', '')

	methods:

		# Create iframe API and watch for it to be ready
		initScript: ->
			# Write the tag
			tag = document.createElement 'script'
			tag.id = 'vimeo-script'
			tag.src = 'https://player.vimeo.com/api/player.js'
			document.body.appendChild tag

			@$wait 1500, =>
				iframe = @$refs.videoContainer.querySelector('iframe')
				iframe.focus()
				iframe.tabIndex = 0

		# Create a player instance
		loadVideo: ->
			@player = new Vimeo.Player '#video-container'
			window.player = @player

		handleDestroy: ->
			if @player
				@player.destroy()
				@player = null
			@$destroy()
			@$el.remove()
			document.getElementById('vimeo-script').remove()

			@focusOnClose.$el.focus()

