


















import Oil from './oil'

export default

	components: {
		Oil
	}

	data: ->
		mounted: false
		fillAnimationTriggered: false

	# Need to hide until mounted to prevent SSR issues because the value of the
	# getter changes client side
	mounted: ->
		@mounted = true
		if @cartQty > 0 then @fillAnimationTriggered = true

	computed:

		# VueX accessor
		cartQty: ->
			if @$store.getters['cart/itemCount'] > 99 then return '99+'
			else return @$store.getters['cart/itemCount']
		cartState: -> @$store.state.cart.open

		# classes
		classes: -> [
			'has-items' if @fillAnimationTriggered
		]

	methods:
		onClick: ->
			@$emit('click')

	watch:
		# watch for cart state change
		cartState: (open, wasOpen) ->
			if !open
				if @$store.getters['cart/itemCount'] > 0 then @fillAnimationTriggered = true
				else @fillAnimationTriggered = false

