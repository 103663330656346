# Deps
import Vue from 'vue'

# Modal
import Modal from '@bkwld/vue-modal'
Vue.component 'modal', Modal
import '@bkwld/vue-modal/index.css'

# Embed
import Embed from '@bkwld/vue-embed'
Vue.component 'vue-embed', Embed

# Cart Flyout
import CartFlyout from 'library/components/layout/cart-flyout/cart-flyout'
Vue.component 'cart-flyout', CartFlyout

# Number Stepper
import NumberStepper from 'library/components/globals/form/number-stepper'
Vue.component 'number-stepper', NumberStepper
