###
Shared focusing and trapping behaviors
###
export default

	data: ->
		focusableElements: null
		focusableContent: null
		firstFocusableElement: null
		lastFocusableElement: null

	methods:

		setTrapWithSpecificElements: (classname) ->
			@releaseAllTraps()
			@focusableElements = '.' + classname
			@firstFocusableElement = document.querySelectorAll(@focusableElements)[0]
			@focusableContent = document.querySelectorAll @focusableElements
			@lastFocusableElement = @focusableContent[@focusableContent.length - 1]
			@firstFocusableElement.focus()
			document.addEventListener 'keydown', @onKeyDown

		# whenever this menu is shown, trap the focus
		# this can happen when the menu is opened
		# or if you've come back to it from a child menu
		setTrap: (el, index = '-1') ->
			@releaseAllTraps()
			@focusableElements = '[tabindex]:not([tabindex="-1"])'
			@firstFocusableElement = el.querySelectorAll(@focusableElements)[0]
			@focusableContent = el.querySelectorAll @focusableElements
			@lastFocusableElement = @focusableContent[@focusableContent.length - 1]
			@firstFocusableElement.focus()
			document.addEventListener 'keydown', @onKeyDown

		# clear the tabbing when this menu is closed
		# this can happen if the menu is closed
		# or if you've navigated to a child menu
		releaseAllTraps: ->
			@focusableElements = null
			@firstFocusableElement = null
			@focusableContent = null
			@lastFocusableElement = null
			document.removeEventListener 'keydown', @onKeyDown

		onKeyDown: (e) ->
			isTabPressed = e.key == 'Tab' or e.keyCode == 9
			return if !isTabPressed
			if e.shiftKey
				if document.activeElement == @firstFocusableElement
					@lastFocusableElement.focus()
					e.preventDefault()
			else
				if document.activeElement == @lastFocusableElement
					@firstFocusableElement.focus()
					e.preventDefault()
