





















import uniq from 'lodash/uniq'
export default

	props: product: Object

	computed:

		# the first variant
		variant: -> @product.variants?[0]

		# Get the prices of all variants
		prices: ->
			uniq @product.variants
			.map (variant) -> variant.price.amount
			.sort @sortMoney

		# Get the compare at prices as long as all variants have a compare at
		# price
		compareAtPrices: ->
			return unless @hasCompareAtPrices
			uniq @product.variants
			.map (variant) -> variant.compareAtPrice.amount
			.sort @sortMoney

		# Check if there are compare prices for all variants
		hasCompareAtPrices: ->
			@product.variants.filter (variant) -> variant.compareAtPrice?.amount
			.length == @product.variants.length

		# Price accessor
		price: -> @$formatMoney @prices[0]

		# determine the sale price, or null
		compareAtPrice: ->
			return unless @compareAtPrices?.length
			@$formatMoney @compareAtPrices[0]

		classes: -> [
			'on-sale' if @compareAtPrice
		]

	methods:

		# Order money strings
		sortMoney: (price1, price2) -> parseFloat(price1) - parseFloat(price2)


