###
Helpers related to formating values
###
import atob from 'atob-lite'
import btoa from 'btoa-lite'
import kebabCase from 'lodash/kebabCase'

# Forward kebab case
export slugify = kebabCase

# Format a string or number like money
export formatMoney = (val) -> '$' + twoDecimals val

# Format money for a product, which may be free
export productCost = (val) ->
	if parseFloat(val) == 0
	then 'Free'
	else formatMoney val

# Add two decimal places
export twoDecimals = (val) ->
	locale = navigator?.language || 'en-US'
	parseFloat(val).toLocaleString locale,
		minimumFractionDigits: 2
		maximumFractionDigits: 2

# Get the id from a Shopify gid:// style id.  This strips everything but the
# last part of the string.  So gid://shopify/ProductVariant/34641879105581
# becomes 34641879105581
# https://regex101.com/r/3FIplL/3
export getShopifyId = (id) ->
	return unless id

	# Already a simple ID
	return id if String(id).match /^\d+$/

	# De-base64. This should only be required when migrating cart ids that were
	# stored in a cookie, AKA client-side pre Storefront API version 2022-04.
	id = atob(id) unless id.match /^gid:\/\//

	# Return the ID
	matches = id.match /\/([^\/?]+)(?:\?.+)?$/
	return matches[1] if matches

# Make a Shopify GID given a object type and id
export makeShopifyId = (type, id) -> btoa "gid://shopify/#{type}/#{id}"

export formatPhone = (phoneNumberString) ->
  cleaned = ('' + phoneNumberString).replace(/\D/g, '')
  match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
  if match
    return '(' + match[1] + ') ' + match[2] + '-' + match[3]
