###
Boot up customer related logic
###
import { addGtmCustomerSubscribers } from 'library/services/gtm/customer-events'
import { hydrateCustomer } from 'library/services/customer/hydrate-customer'
export default ({ app, store }, inject) ->

	# Fire GTM events
	addGtmCustomerSubscribers store, app.$gtmEcomm

	# Trigger hydration of customer and inject it's promise globally as a helper
	inject 'customerHydrated', hydrateCustomer store
