
















import cartTotals from 'library/mixins/cart-totals'

export default
	mixins: [ cartTotals ]

	computed:
		styles: -> {
			width: @calcPercentageAway + "%"
		}

