














import SubNav from './sub-nav'
import TopLevel from './shared/top-level'
import trapFocusMixin from 'library/mixins/trap-focus-mixin'
import { wait } from '@bkwld/cloak/services/helpers'

export default

	mixins: [trapFocusMixin]

	components: { SubNav, TopLevel }

	props:
		item: Object

	data: ->
		showSubnav: false

	computed:
		hasChildren: -> @item.items?.length > 0
		children: -> @item.items?[0]?.links

	methods:
		onClick: -> @showSubnav = true
		closePane: ->
			@$emit('closePane')
			@showSubnav = false

