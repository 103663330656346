









export default

	methods:
		onClick: ->
			@$emit 'overlay-clicked'

