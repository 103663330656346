














export default

	props:
		item: Object
		tabIndex:
			type: String
			default: '-1'

	computed:
		title: -> @item.items?[0]?.title || @item.title

	methods:
		onClick: -> @$emit('click')

