

























import { isDark } from '@bkwld/light-or-dark'

export default

	props:
		cta: Object

	computed:
		btnTxt: -> @cta.toutButtonText
		url: -> @cta.toutLink
		image: -> @cta.image
		title: -> @cta.toutTitle
		body: -> @cta.toutDescription

		# Is it a dark background color
		isDark: -> isDark @cta.toutBackground if @cta.toutBackground

		# Root classes
		classes: -> [
			if @isDark then 'fg-light' else 'fg-dark'
		]

		styles: -> [
			'backgroundColor': @cta.toutBackground
		]


