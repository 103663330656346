


















export default

	data: ->
		menuOpen: false

	computed:
		classes: -> 'menu-open' if @menuOpen
		expandedLabel: ->
			if @menuOpen then return 'true'
			else return 'false'

	methods:
		onClick: -> @$emit('click')

		toggle: (open) ->
			if open then @menuOpen = true
			else @menuOpen = false

