


























export default

	props:
		product: Object

	computed:
		variant: -> @product.variants?[0]

		# price is formatted differently coming
		# from shopify. for example price: 3358 for $33.58
		# UGH
		price: ->
			price = @variant.price.toString()
			seg1 = price.substring(0, price.length - 2)
			seg2 = price.slice(-2)
			decimalPrice =  seg1 + '.' + seg2
			return @$formatMoney decimalPrice

	methods:
		addToCart: ->

			# simple payload
			payload = {
				id: @variant.id
				quantity: 1
			}

			@$store.dispatch 'cart/addItem', payload


