import { render, staticRenderFns } from "./olio-nuovo-lines.vue?vue&type=template&id=48f43eae&scoped=true&lang=pug&"
import script from "./olio-nuovo-lines.vue?vue&type=script&lang=coffee&"
export * from "./olio-nuovo-lines.vue?vue&type=script&lang=coffee&"
import style0 from "./olio-nuovo-lines.vue?vue&type=style&index=0&id=48f43eae&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "48f43eae",
  null
  
)

export default component.exports