























export default

	props:
		product: Object
		image: Object
		index: Number

	# this was needed so that when you client-side
	# navigate away from this page (with the product zoomer)
	# you don't get mouse position errors, which result in a 404
	data: ->
		loadedAndShown: false

	mounted: ->
		@loadedAndShown = true

	destroyed: ->
		@loadedAndShown = false

	computed:
		alt: -> @image?.altText || ''
		pos: -> [0.5,0.5]

