import { render, staticRenderFns } from "./quick-shop.vue?vue&type=template&id=938f026e&scoped=true&lang=pug&"
import script from "./quick-shop.vue?vue&type=script&lang=coffee&"
export * from "./quick-shop.vue?vue&type=script&lang=coffee&"
import style0 from "./quick-shop.vue?vue&type=style&index=0&id=938f026e&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "938f026e",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {PdpVariantCarousel: require('/opt/build/repo/nuxt-app/components/pages/pdp/variant-carousel.vue').default,SmartLink: require('/opt/build/repo/nuxt-app/components/globals/smart-link.coffee').default,PdpVariantSelector: require('/opt/build/repo/nuxt-app/components/pages/pdp/variant-selector.vue').default})
