###
Shared code used to calculate totals
###

export default

	computed:
		# Get the subtotal
		subtotal: -> @$store.getters['cart/subtotal']

		# Get the total, ignoring tax at this time
		total: -> @$store.getters['cart/total'] - @$store.getters['cart/tax']

		# Simplify the discount codes
		discountCodes: -> @$store.state.cart.discountCodes.map ({ code }) -> code

		# Helper to check if discount code is a free shipping code
		shippingDiscount: -> @discountCodes.some (code) ->
			shippingCode = /^(FREESHIP-|HOLIDAYSHIP)/
			return (shippingCode.test code.toUpperCase())

		# Helpers related to cart contents
		hasSubscription: -> @$store.getters['cart/hasSubscription']
		hasNonSubscription: -> @$store.getters['cart/hasNonSubscription']

		# client asked us to remove subscription-specific shipping messages
		# https://app.asana.com/0/1200777795870740/1202014932862280/f
		shippingCost: -> switch
			when !@remaingShippingMoney or @shippingDiscount
				'Free shipping on this order.'
			else "You're <span>#{@remaingShippingMoney}</span> away from <span>FREE Shipping!</span>"

		calcPercentageAway: ->
			return 100 if @total >= 85.00
			@total/85*100

		# Remaing shipping cost as a string
		remaingShippingMoney: ->
			return false if @total >= 85.00
			@$formatMoney 85.00 - @total
