



























import inputMixin from 'library/mixins/input-mixin'

export default

	mixins: [inputMixin]

	props:
		variants: Array
		options:
			type: Array
			default: -> []
		loading: Boolean
		type:
			type: String
			default: 'button'
		purchaseType: String

	# Make options rendered to preserve reusability of component
	computed: renderedOptions: -> @options.map @mapOption

	methods:
		mapOption: (option) ->
			if 'object' == typeof option then option
			else { label: option, value: option}

		# line 1
		buildLabelLine1: (option) ->

			# get variant from value
			variant = @variants.find((variant) -> variant.title == option.label)

			if variant.variant_line_1? then return variant.variant_line_1.value
			else return option.label

		buildLabelLine2: (option) ->

			# get variant from value
			variant = @variants.find((variant) -> variant.title == option.label)

			if variant.variant_line_2? then return variant.variant_line_2.value
			else return ''

		buildLabelDescriptionOtp: (option) ->

			# get variant from value
			variant = @variants.find((variant) -> variant.title == option.label)

			if variant.variant_description_otp? then return variant.variant_description_otp.value
			else return ''

		buildLabelDescriptionRecurring: (option) ->

			# get variant from value
			variant = @variants.find((variant) -> variant.title == option.label)

			if variant.variant_description_recurring? then return variant.variant_description_recurring.value
			else return ''

