









import { isDark } from '@bkwld/light-or-dark'

export default

	computed:
		banner: -> @$store?.state?.globals?.banner
		body: -> @banner?.copy
		bkgd: -> @banner?.backgroundColor || '#18322F'
		style: ->
			backgroundColor: @bkgd
			color: @textColor
		textColor: ->
			if isDark @bkgd then return 'white'
			else return 'black'

