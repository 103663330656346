import { render, staticRenderFns } from "./cart-upsell.vue?vue&type=template&id=5aaeb9e0&scoped=true&lang=pug&"
import script from "./cart-upsell.vue?vue&type=script&lang=coffee&"
export * from "./cart-upsell.vue?vue&type=script&lang=coffee&"
import style0 from "./cart-upsell.vue?vue&type=style&index=0&id=5aaeb9e0&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5aaeb9e0",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SsrCarousel: require('/opt/build/repo/node_modules/vue-ssr-carousel/index.js').default})
