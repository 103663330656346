


















import inputMixin from 'library/mixins/input-mixin'

export default
	mixins: [inputMixin]

	props:
		allocations: Array
		# Loading state is used in flyout menu
		loading: Boolean

	computed:

		# Make the select menu options, removing the "delvery every" in the name
		options: -> @allocations.map ({ sellingPlan }) ->
			value: sellingPlan.name.replace /delivery every\w*/i, ''
			key: sellingPlan.id

