










export default

	props:
		buttons: Array
		size:
			type: String
			default: 'semi-wide'

	methods:
		hasProduct: (button) ->
			if button?.product?.length > 0 then return true
			else return false

