



































import { isDark } from '@bkwld/light-or-dark'

export default
	inject: [ 'blocks' ]

	props:
		block: Object

	mounted: ->
		@scroller = document.querySelector 'html'
		document.addEventListener 'scroll', @onScroll

	beforeDestroy: -> window.removeEventListener 'scroll', @onScroll

	computed:

		# check to see if the bg setting on the block
		# is a dark color or not
		isDark: -> isDark @block.backgroundColor if @block?.backgroundColor

		classes: -> [
			@block.layout
			'bg-dark' if @isDark
			'tall' if @block.tallMarquee
			'no-fg-image' if @block.foregroundImage.length == 0
		]

		shadowClasses: -> [
			'hide' if @block.hideShadow
		]

		sizeClasses: -> [
			"bottle-size-#{@block.bottleSize}" if @block.bottleSize
		]

		styles: ->
			backgroundColor: @bgColor

		bgColor: ->
			if @block?.backgroundColor then return @block?.backgroundColor
			else return 'transparent'

		hasButtons: -> !!@block?.buttons?.length

		colorSample: ->
			# Return if we somehow don't have blocks
			return unless @blocks.length

			# Enclose block for function scope
			block = @block

			# Get our current block position in the list
			blockIdx = @blocks.findIndex (testBlock) -> testBlock.id == block.id
			nextBlock = @blocks[blockIdx + 1]

			# If next block in list isn't a wrapper, we don't change color
			return unless nextBlock?.componentName == 'blocks-wrapper'

			# Set background color
			return backgroundColor: nextBlock.backgroundColor

	methods:
		onScroll: (e) ->
			@$refs.fgWrap?.style.transform = "translateY(-#{window.scrollY/7}px)"

