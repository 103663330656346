



























import atob from 'atob-lite'

export default

	props:
		images: Array
		product: Object
		variant: Object

	data: ->
		config:
			slidesPerPage: 1
			showDots: true
			showArrows: true

	computed:

		variants: -> @product.variants

		variantImageIds: -> @variants.map (variant) => variant.image.id

		gallery: ->

			# if a bundle, then the images prop will
			# contain the craft images associated with the
			# bundle, so just return that
			if @images then return @images

			# else, assemble variant specific galleries

			# grab the variant image
			initial = @product?.images?.filter (img) =>

				# this comparison works even though
				# these values are encoded
				img.id == @variant?.image?.id

			# additional images (like back or lifestyle)
			additional = @product?.images?.filter (img) =>
				!@variantImageIds.includes img.id

			# combine initial and additional
			if @variant.id == @product.variants[0].id
				imgs = initial.concat additional
			else imgs = initial

			return imgs || [@product?.images?[0]]

