















export default

	computed:
		nav: -> @$store.state.globals.footerNavigation
		menus: -> @nav?.items || []

