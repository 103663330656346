
















import sunrise from 'library/assets/images/sunrise.png'
import FooterBottom from './footer-bottom'
import FooterMenus from './footer-menus'

export default

	components: {
		FooterBottom
		FooterMenus
	}

	data: -> { sunrise }

