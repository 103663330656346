













































import { isDark } from '@bkwld/light-or-dark'

export default

	props: block: Object

	computed:
		showButtons: -> !!@block?.buttons?.length

		showBreadcrumbs: -> !!@block?.breadcrumbs?.length

		hasBkgImage: -> !!@block?.backgroundImage?[0]?.landscape?.length || !!@block?.backgroundImage?[0]?.mobile?.length

		# Is it a dark background color
		isDark: -> isDark @block.backgroundColor if @block?.backgroundColor

		# Root classes
		classes: -> [
			if (@hasBkgImage || @isDark) then 'fg-light' else 'fg-dark'
			if @block?.breadcrumbAlign then "breadcrumbs-#{@block.breadcrumbAlign}" else 'breadcrumbs-center'
		]

