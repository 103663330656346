


























import inputMixin from 'library/mixins/input-mixin'

export default
	name: "formRadioList"

	mixins: [ inputMixin ]

	props:
		options:
			type: Array
			default: -> []

		value: String | Number

		type:
			type: String
			default: "standard"

		groupLabel:
			type: String
			default: 'radio-group'

		loading: Boolean

	methods:
		tabindex: (index) ->
			if index == 0 then return '0'
			else return '-1'

