###
Send GTM dataLayer events in response to cart actions
###

# Main function that adds the subscribers
export addGtmCartSubscribers = (store, gtmEcomm) ->

	# Delegate relevant actions
	store.subscribeAction
		before: ({ type, payload }) -> switch type
			when 'cart/addItem' then fireAddItem payload
			when 'cart/addBundle' then fireAddBundle payload
			when 'cart/updateLine' then fireUpdateItem payload
		after: ({ type, payload }) -> switch type
			when 'cart/addItem', 'cart/updateLine'
			then gtmEcomm.cartUpdated store.state.cart.id

	# Item added to cart
	fireAddItem = ({ id, quantity }) -> gtmEcomm.addToCart id, quantity

	# Firre addItem for all bundle variants
	fireAddBundle = ({ variantIds, quantity }) ->
		fireAddItem { id, quantity } for id in variantIds

	# Item quantity updated
	fireUpdateItem = ({ id: lineId, quantity }) ->

		# Fetch cart if not hydrated yet
		await store.dispatch 'cart/fetchUnlessHydrated'

		# Inspect the line
		line = getline lineId
		diff = quantity - line.quantity
		return if diff == 0

		# Get all the touched variant ids
		variantIds = unless line.bundleLines then [ line.variant.id ]
		else line.bundleLines.map ({ variant }) -> variant.id

		# Fire events
		for variantId in variantIds
			if diff > 0
			then gtmEcomm.addToCart variantId, diff
			else gtmEcomm.removeFromCart variantId, Math.abs diff

	# Lookup the line item
	getline = (lineId) ->
		line = store.getters['cart/lines'].find (line) ->
			line.id == lineId
		unless line then throw "Variant not found: #{variantGid}"
		return line
