











































import inputMixin from 'library/mixins/input-mixin'

isInteger = (val) -> !!String(val).match /^\d+$/

export default
	mixins: [inputMixin]

	props:

		min:
			type: Number
			default: 1

		max:
			type: Number
			default: 999

		value:
			type: Number | String
			validator: isInteger

		# Loading state is used in flyout menu
		loading: Boolean

	# Overwrite state watcher to parse integers
	data: ->
		state: parseInt @value
		displayMaxed: false
		timeout: undefined

	methods:
		#(don't check bounds on state change to allow clearing while typing)
		# Increment by 1 and check bounds
		increase: ->
			return if @loading
			@state = @applyBounds @state + 1

		# Decrement by 1 and check bounds
		decrease: ->
			return if @loading
			@state = @applyBounds @state - 1

		# Check that value is within provided range
		applyBounds: (val) ->
			if val > @max then @displayMaxed = true
			Math.min @max, Math.max @min, val

		# Check the range if valid, otherwise set to min
		onBlur: ->
			if isInteger @state then @state = @applyBounds @state
			else @state = @min

	watch:
		displayMaxed: (state) ->
			if state
				if @timeout then clearTimeout @timeout
				@timeout = setTimeout =>
					@displayMaxed = false
				, 2000

	computed:

		ariaLabel: -> if @label then "#{@label} Quantity" else 'Quantity'
		decreaseLabel: -> "Decrease Quantity to #{@state - 1}"
		increaseLabel: -> "Increase Quantity to #{@state + 1}"

		removeDisabled: -> @state == @min

		maxQuantityMsg: -> "Max of #{@max}"


