
























export default

	props:
		item: Object
		tabIndex: String
		children: Array
		tertiary:
			type: Boolean
			default: false

	computed:
		hasChildren: -> @children?.length > 0
		link: -> @item.href || @item.headingLink
		tertiaryLinks: -> @tertiary && @item.childLinks?.length > 0

	methods:
		onClick: -> @$emit('clicked')

