###
Conditionally render the appropriate Visual instance depending on whether the
product is a bundle or not.
###
import CraftVisual from '@bkwld/cloak/components/craft-visual'
import ShopifyVisual from './shopify-visual'
export default
	functional: true

	props: {

		# Support all Craft props
		...CraftVisual.props

		# Pass in product objects
		product: Object

		# Or specify bundle-ness directly
		isBundle: Boolean
	}

	# Determine which component to render
	render: (create, { props, data }) ->

		# Component
		component = if props.isBundle or props.product?.isBundle
		then CraftVisual else ShopifyVisual

		# Remove product from Visual props
		visualProps = { ...props, product: undefined }

		# Make a default alt title
		if props.image?.altText then visualProps.alt = props.image?.altText
		unless props.alt or props.image?.altText or props.image?.title
			if props.product
			then visualProps.alt = "#{props.product.title} product packaging"

		# Set the object-position to where the "floor" of the background gradient
		# is expected to intersect with the product, 77% down from the top
		visualProps.objectPosition = '50% 77%'
		visualProps.objectFit = 'contain'

		# Product images are transparent pngs, don't do a placeholder
		visualProps.noPlaceholder = true

		# Images are expected to be square
		visualProps.aspect = 1

		# Render the visual
		create component, {
			...data
			props: visualProps
		}
