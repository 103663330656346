











import trapFocusMixin from 'library/mixins/trap-focus-mixin'
import SubNavItem from './sub-nav-item'
import Heading from './shared/heading'
import { wait } from '@bkwld/cloak/services/helpers'
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock'

export default

	mixins: [trapFocusMixin]

	components: { SubNavItem, Heading }

	props:
		item: Object

	mounted: ->
		@initNavLock()

	computed:
		children: -> @item.items?[0]?.links

	methods:
		closePane: ->
			@$emit('closePane')

		initNavLock: ->
			clearAllBodyScrollLocks()
			wait 100,
				@scrollAndTrapSubNav()

		scrollAndTrapSubNav: ->
			disableBodyScroll @$refs.subnavScroller
			@setTrap(@$refs.subnavScroller, '1')


