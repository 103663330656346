









import NavItem from './item'

export default

	components: { NavItem }

	computed:
		navigationData: -> @$store.state.globals.desktopNavigation
		navigationItems: -> @navigationData?.items || []

