import { render, staticRenderFns } from "./purchase-options.vue?vue&type=template&id=6531d1d0&scoped=true&lang=pug&"
import script from "./purchase-options.vue?vue&type=script&lang=coffee&"
export * from "./purchase-options.vue?vue&type=script&lang=coffee&"
import style0 from "./purchase-options.vue?vue&type=style&index=0&id=6531d1d0&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6531d1d0",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {FormRadioList: require('/opt/build/repo/library/components/globals/form/radio-list.vue').default,FormSubscriptionIntervalSelector: require('/opt/build/repo/library/components/globals/form/subscription-interval-selector.vue').default,FormNumberStepper: require('/opt/build/repo/library/components/globals/form/number-stepper.vue').default,Btn: require('/opt/build/repo/nuxt-app/components/globals/btn/btn.vue').default})
