import { render, staticRenderFns } from "./half-marquee.vue?vue&type=template&id=92c308ea&scoped=true&lang=pug&"
import script from "./half-marquee.vue?vue&type=script&lang=coffee&"
export * from "./half-marquee.vue?vue&type=script&lang=coffee&"
import style0 from "./half-marquee.vue?vue&type=style&index=0&id=92c308ea&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "92c308ea",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {OlioNuovoLines: require('/opt/build/repo/library/components/globals/olio-nuovo-lines.vue').default,CraftVisual: require('/opt/build/repo/node_modules/@bkwld/cloak/components/craft-visual.coffee').default,ResponsiveCraftVisual: require('/opt/build/repo/node_modules/@bkwld/cloak/components/responsive-craft-visual.vue').default,TraceTable: require('/opt/build/repo/nuxt-app/components/globals/trace/table.vue').default,AccessibilityBtn: require('/opt/build/repo/library/components/globals/accessibility-btn.vue').default,Wysiwyg: require('/opt/build/repo/node_modules/@bkwld/cloak/components/wysiwyg.coffee').default,BtnQuickShop: require('/opt/build/repo/nuxt-app/components/globals/btn/quick-shop.vue').default,Btn: require('/opt/build/repo/nuxt-app/components/globals/btn/btn.vue').default,BlocksBtnList: require('/opt/build/repo/nuxt-app/components/blocks/btn-list.vue').default})
