










import cartTotals from 'library/mixins/cart-totals'

export default
	mixins: [ cartTotals ]

