import { render, staticRenderFns } from "./sub-nav-item.vue?vue&type=template&id=d31d8632&scoped=true&lang=pug&"
import script from "./sub-nav-item.vue?vue&type=script&lang=coffee&"
export * from "./sub-nav-item.vue?vue&type=script&lang=coffee&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d31d8632",
  null
  
)

export default component.exports