





















import inputMixin from 'library/mixins/input-mixin'
export default

	mixins: [ inputMixin ]

	props:
		name: String
		label: String
		value: Boolean

		# Loading state is used in flyout menu
		loading: Boolean

	methods:



		# Handle clicks
		onClick: ->
			return if @loading
			@state = !@state
			@$emit 'click', @state

