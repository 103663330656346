
































import CartClose from './cart-close'
import CartHeader from './cart-header'
import CartFooter from './cart-footer'
import CartLineItems from './cart-line-items'
import CartUpsell from './cart-upsell'
import CartFreeShipping from './cart-free-shipping'
import Overlay from 'library/components/globals/overlay'
import trapFocusMixin from 'library/mixins/trap-focus-mixin'
import { clearAllBodyScrollLocks } from 'body-scroll-lock'

export default

	mixins: [trapFocusMixin]

	components: {
		CartHeader
		CartFooter
		CartLineItems
		CartUpsell
		CartFreeShipping
		Overlay
		CartClose
	}

	computed:
		cart: -> @$store.state.cart
		lineItems: ->@$store.state.cart.lines
		hasItems: -> @$store.getters['cart/itemCount'] > 0

	methods:
		closeCart: ->
			@releaseAllTraps()
			@$store.commit("cart/close")

	watch:
		'$store.state.cart.open': (open) ->
			if open
				@$wait 50, =>
					@setTrap(@$refs.cartFlyout)
					@$refs.header.focusClose()
					@$refs.lineItems.lockScroll()
			else
				# re-focus on the cart icon
				document.getElementById('cart-icon-button').focus()
				clearAllBodyScrollLocks()


		# When the route changes while open, close the cart
		'$route.path': -> @closeCart() if @$store.state.cart.open

