
import { isDark } from '@bkwld/light-or-dark'
import SmartLink from '~/components/globals/smart-link'
export default

	props:

		# URL to link to
		to: String

		# Size of the button
		size:
			type: String
			default:'small'
			# wide: same height as small, but more padding-h
			# checkout: special custom size
			validator: (val) -> val in ['xs', 'small', 'large', 'semi-wide', 'wide', 'checkout']


		# If a <button>, what type is it
		type:
			type: String
			default: 'button'
			validator: (val) -> val in ['button', 'submit', 'reset']

		# Background color of the button
		color:
			type: String
			default: 'dark-green'
			validator: (val) -> val in [
					'dark-green', 'light-blue', 'light-green', 'light-red', 'red'
					# Color aliases.  'primary' maps to dark green. 'secondary' maps to light-blue.
					'primary', 'secondary'
				]

		# Inverted has white bkg and 'color' text
		fill:
			type: String
			default: 'solid'
			validator: (val) -> val in ['solid', 'inverted', 'invertedOutline']

		# Shape of the button
		shape:
			type: String
			default: 'pill'
			# pill-right: round right side, flat left side
			# circle: often no text, just an icon
			validator: (val) -> val in ['pill', 'pill-right', 'circle']

		# When true, button expands to 100% width
		expand:
			type: Boolean
			default: false

		# Button state
		loading: Boolean
		disabled: Boolean

		# optional aria-label
		ariaLabel: String

	computed:

		# Convert color aliases to colors
		colorComputed: -> switch @color
			when 'primary' then return 'dark-green'
			when 'secondary' then return 'light-blue'
			else return @color

		# Root classes
		classes: -> [
			"size-#{@size}" if @size
			"color-#{@colorComputed}" if @colorComputed
			"fill-#{@fill}" if @fill
			"shape-#{@shape}" if @shape
			loading: @loading
			expand: @expand
		]

		# States that should supress clicks
		nonInteractive: -> @disabled or @loading

		# Get good text color for background
		textColor: ->
			return unless @color.length
			if isDark @color then 'white' else 'black'

	methods:

		# Prevent clicking if non-interactive
		onClick: (e) ->
			if @nonInteractive
			then e.preventDefault()
			else @$emit 'click'

	# Conditionally change the root button type
	render: (create) ->

		# Make the shared data object
		data =
			class: ['btn', ...@classes]
			domProps: disabled: @nonInteractive
			on: click: @onClick

		# The contents of the button
		children = [
			create 'span', {class: 'shape'}
			create 'span', {class: 'slot'}, @$nonEmpty [
				@$slots.default
				if @loading then create 'span', class: 'icon-spinner'
			]
		]

		# Render a smart-link
		if @to
			create SmartLink, {
				...data
				attrs: {
					...data.attrs
					'aria-label': @ariaLabel if @ariaLabel
				}
				props: { @to }
			}, children

		# Render an html button
		else
			create 'button', {
				...data
				domProps: {
					...data.domProps
					@type
				}
			}, children

