import { render, staticRenderFns } from "./breadcrumbs.vue?vue&type=template&id=dc94ecf0&scoped=true&lang=pug&"
import script from "./breadcrumbs.vue?vue&type=script&lang=coffee&"
export * from "./breadcrumbs.vue?vue&type=script&lang=coffee&"
import style0 from "./breadcrumbs.vue?vue&type=style&index=0&id=dc94ecf0&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dc94ecf0",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SmartLink: require('/opt/build/repo/nuxt-app/components/globals/smart-link.coffee').default})
