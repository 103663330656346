



















import inputMixin from 'library/mixins/input-mixin'
export default

	mixins: [ inputMixin ]

	props:
		options: Array
		name: String
		size: String
		half: Boolean
		hideLabel:
			type: Boolean
			default: false
		id:
			type: String
			default: 'select-element'

		# Loading state is used in flyout menu
		loading: Boolean

		customId: type: String

	computed:

		classes: -> [
			"size-#{@size}" if @size
			'half' if @half
		]

		labelClasses: -> [
			'visually-hidden' if @hideLabel
		]

		computedLabel: -> "#{@label}#{if @required then ' *' else ''}"



