




























export default

	props:
		tableHeading: Array
		tableData: Array
		traceData: Object
		full:
			type: Boolean
			default: false
		caption:
			type: String
			default: ''
		copy: String
		regionClick:
			type: Boolean
			default: false

	computed:
		widthClasses: -> [
			'full' if @full
		]

	methods:
		onClick: (label) -> @$emit 'clicked', label

