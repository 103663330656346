












import CartClose from './cart-close'

export default

	components: { CartClose }

	computed:
		cartCount: ->
			count = @$store.getters['cart/itemCount']
			"#{count}"

	methods:
		closeCart: -> @$emit 'close'
		focusClose: -> @$refs.close.$el.focus()

