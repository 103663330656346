











import LineItem from './line-item'
import { disableBodyScroll } from 'body-scroll-lock'

export default

	components: {
		LineItem
	}

	computed:
		lineItems: -> @$store.getters['cart/lines']
		hasItems: -> @$store.getters['cart/itemCount'] > 0

	methods:
		lockScroll: -> disableBodyScroll(@$refs.scroller)

